import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

import SearchBox from './SearchBox.js';
import Results from './Results.js';
import Waiting from './Waiting.js';

function submitForm(query) {
  return fetch('//tiptoe.csail.mit.edu/api/search?' + new URLSearchParams({
    q: query,
    num: 50,
  }));
}

function App() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [resQuery, setResQuery] = useState('');
  const [status, setStatus] = useState('waiting');
  const [error, setError] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus('submitting');
    setResQuery('');
    try {
      const res = await submitForm(query);
      if(res.ok) {
        setResults(await res.json());
        setResQuery(query);
        setQuery('');
        setStatus('success');
      } else {
        setStatus('waiting');
        setError('Error: ' + res.status);
      } 
    } catch (err) {
      setStatus('waiting');
      setError(err);
    }
  }

  function handleQueryChange(e) {
    e.preventDefault();
    setQuery(e.target.value);
  }

  return (
    <div className="App">
      <header className="App-header">
        <form onChange={ handleQueryChange } onSubmit={ handleSubmit }>
          <SearchBox 
            disabled={status === 'submitting'}
            value={query}
          />
        </form>
      </header>
      { status === 'submitting' &&  <Waiting /> }
      { status === 'success' && <Results query={resQuery} results={results}/> }
    </div>
  );
}

export default App;
