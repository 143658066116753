import Highlighter from "react-highlight-words";

import './Results.css';

export default function Results({ query, results }) {
  const listItems = results.map((res) =>
    <li> <a key={ res.url } href={ res.url }><Highlighter 
      autoEscape={true}
      searchWords={query.split(/(\s+)/)}
      highlightClassName="matchingWord"
      textToHighlight={ res.url } 
    /></a>
    <span className="score">{res.score}</span>

    </li>
  );
  return <div className="results">
    <h2>Search results: {query}</h2>
    <ol>
    { listItems }
    </ol>
  </div>
}
